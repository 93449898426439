import React from 'react'
import Layout from '../components/layout'
import Head from '../components/head'


const contact = () => {
    return (
        <div>
                <Layout>
                    <Head title="Contact" />
                                    <p>For any query kindly write us at blog.support@vidveda.com</p>

                </Layout>
            
        </div>
    )
}

export default contact
